/*!
Video.js Default Styles (http://videojs.com)
Version 4.12.15
Create your own skin at http://designer.videojs.com
*/
.vjs-default-skin {
  color: #ccc;
}

@font-face {
  font-family: VideoJS;
  src: url("vjs.620c01ca.eot");
  src: url("vjs.620c01ca.eot#iefix") format("embedded-opentype"), url("vjs.53ca969a.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

.vjs-default-skin .vjs-slider {
  cursor: pointer;
  background-color: #333333e6;
  outline: 0;
  padding: 0;
  position: relative;
}

.vjs-default-skin .vjs-slider:focus {
  box-shadow: 0 0 2em #fff;
}

.vjs-default-skin .vjs-slider-handle {
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-default-skin .vjs-slider-handle:before {
  content: "";
  text-align: center;
  text-shadow: 0 0 1em #fff;
  font-family: VideoJS;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
}

.vjs-default-skin .vjs-control-bar {
  background-color: #07141eb3;
  height: 3em;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.vjs-default-skin.vjs-has-started .vjs-control-bar {
  visibility: visible;
  opacity: 1;
  -o-transition: visibility .1s, opacity .1s;
  transition: visibility .1s, opacity .1s;
  display: block;
}

.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: hidden;
  opacity: 0;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
  display: block;
}

.vjs-default-skin.vjs-controls-disabled .vjs-control-bar, .vjs-default-skin.vjs-using-native-controls .vjs-control-bar, .vjs-default-skin.vjs-error .vjs-control-bar {
  display: none;
}

.vjs-audio.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible;
}

@media �screen {
  .vjs-default-skin.vjs-user-inactive.vjs-playing .vjs-control-bar :before {
    content: "";
  }
}

.vjs-default-skin .vjs-control {
  float: left;
  text-align: center;
  outline: 0;
  width: 4em;
  height: 3em;
  margin: 0;
  padding: 0;
  position: relative;
}

.vjs-default-skin .vjs-control:before {
  text-align: center;
  text-shadow: 1px 1px 1px #00000080;
  width: 100%;
  height: 100%;
  font-family: VideoJS;
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-default-skin .vjs-control:focus:before, .vjs-default-skin .vjs-control:hover:before {
  text-shadow: 0 0 1em #fff;
}

.vjs-default-skin .vjs-control-text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.vjs-default-skin .vjs-play-control {
  cursor: pointer;
  width: 5em;
}

.vjs-default-skin .vjs-play-control:before {
  content: "";
}

.vjs-default-skin.vjs-playing .vjs-play-control:before {
  content: "";
}

.vjs-default-skin .vjs-playback-rate .vjs-playback-rate-value {
  text-align: center;
  text-shadow: 1px 1px 1px #00000080;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-default-skin .vjs-playback-rate.vjs-menu-button .vjs-menu .vjs-menu-content {
  width: 4em;
  list-style: none;
  left: -2em;
}

.vjs-default-skin .vjs-mute-control, .vjs-default-skin .vjs-volume-menu-button {
  cursor: pointer;
  float: right;
}

.vjs-default-skin .vjs-mute-control:before, .vjs-default-skin .vjs-volume-menu-button:before {
  content: "";
}

.vjs-default-skin .vjs-mute-control.vjs-vol-0:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-0:before {
  content: "";
}

.vjs-default-skin .vjs-mute-control.vjs-vol-1:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-1:before {
  content: "";
}

.vjs-default-skin .vjs-mute-control.vjs-vol-2:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-2:before {
  content: "";
}

.vjs-default-skin .vjs-volume-control {
  float: right;
  width: 5em;
}

.vjs-default-skin .vjs-volume-bar {
  width: 5em;
  height: .6em;
  margin: 1.1em auto 0;
}

.vjs-default-skin .vjs-volume-level {
  background: #66a8cc url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC") -50% 0;
  width: 100%;
  height: .5em;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-default-skin .vjs-volume-bar .vjs-volume-handle {
  width: .5em;
  height: .5em;
  left: 4.5em;
}

.vjs-default-skin .vjs-volume-handle:before {
  width: 1em;
  height: 1em;
  font-size: .9em;
  top: -.2em;
  left: -.2em;
}

.vjs-default-skin .vjs-volume-menu-button .vjs-menu {
  border-top-color: #0000;
  width: 0;
  height: 0;
  display: block;
}

.vjs-default-skin .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
  width: 0;
  height: 0;
}

.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu, .vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing {
  border-top-color: #07283280;
}

.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content, .vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content {
  width: 10em;
  height: 2.9em;
}

.vjs-default-skin .vjs-progress-control {
  -o-transition: all .4s;
  width: auto;
  height: 1em;
  font-size: .3em;
  transition: all .4s;
  position: absolute;
  top: -1em;
  left: 0;
  right: 0;
}

.vjs-default-skin:hover .vjs-progress-control {
  -o-transition: all .2s;
  font-size: .9em;
  transition: all .2s;
}

.vjs-default-skin .vjs-progress-holder {
  height: 100%;
}

.vjs-default-skin .vjs-progress-holder .vjs-play-progress, .vjs-default-skin .vjs-progress-holder .vjs-load-progress, .vjs-default-skin .vjs-progress-holder .vjs-load-progress div {
  width: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-default-skin .vjs-play-progress {
  background: #66a8cc url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC") -50% 0;
}

.vjs-default-skin .vjs-load-progress {
  background: #fff3;
}

.vjs-default-skin .vjs-load-progress div {
  background: #ffffff1a;
}

.vjs-default-skin .vjs-seek-handle {
  width: 1.5em;
  height: 100%;
}

.vjs-default-skin .vjs-seek-handle:before {
  padding-top: .1em;
}

.vjs-default-skin.vjs-live .vjs-time-controls, .vjs-default-skin.vjs-live .vjs-time-divider, .vjs-default-skin.vjs-live .vjs-progress-control {
  display: none;
}

.vjs-default-skin.vjs-live .vjs-live-display {
  display: block;
}

.vjs-default-skin .vjs-live-display {
  font-size: 1em;
  line-height: 3em;
  display: none;
}

.vjs-default-skin .vjs-time-controls {
  font-size: 1em;
  line-height: 3em;
}

.vjs-default-skin .vjs-current-time, .vjs-default-skin .vjs-duration {
  float: left;
}

.vjs-default-skin .vjs-remaining-time {
  float: left;
  display: none;
}

.vjs-time-divider {
  float: left;
  line-height: 3em;
}

.vjs-default-skin .vjs-fullscreen-control {
  cursor: pointer;
  float: right;
  width: 3.8em;
}

.vjs-default-skin .vjs-fullscreen-control:before {
  content: "";
}

.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
  content: "";
}

.vjs-default-skin .vjs-big-play-button {
  z-index: 2;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  -o-transition: all .4s;
  background-color: #07141eb3;
  border: .1em solid #3b4249;
  border-radius: .8em;
  width: 4em;
  height: 2.6em;
  font-size: 3em;
  transition: all .4s;
  display: block;
  position: absolute;
  top: .5em;
  left: .5em;
  box-shadow: 0 0 1em #ffffff40;
}

.vjs-default-skin.vjs-big-play-centered .vjs-big-play-button {
  margin-top: -1.4em;
  margin-left: -2.1em;
  top: 50%;
  left: 50%;
}

.vjs-default-skin.vjs-controls-disabled .vjs-big-play-button, .vjs-default-skin.vjs-has-started .vjs-big-play-button, .vjs-default-skin.vjs-using-native-controls .vjs-big-play-button {
  display: none;
}

.vjs-default-skin:hover .vjs-big-play-button, .vjs-default-skin .vjs-big-play-button:focus {
  -o-transition: all 0s;
  background-color: #323232bf;
  border-color: #fff;
  outline: 0;
  transition: all;
  box-shadow: 0 0 3em #fff;
}

.vjs-default-skin .vjs-big-play-button:before {
  content: "";
  text-shadow: .05em .05em .1em #000;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: VideoJS;
  line-height: 2.6em;
  position: absolute;
  left: 0;
}

.vjs-error .vjs-big-play-button, .vjs-error-display {
  display: none;
}

.vjs-error .vjs-error-display {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-error .vjs-error-display:before {
  content: "X";
  color: #666;
  text-shadow: .05em .05em .1em #000;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  margin-top: -.5em;
  font-family: Arial;
  font-size: 4em;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 0;
}

.vjs-error-display div {
  text-align: center;
  background: #00000080;
  padding: 3px;
  font-size: 1.4em;
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
}

.vjs-error-display a, .vjs-error-display a:visited {
  color: #f4a460;
}

.vjs-loading-spinner {
  opacity: .75;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: 4em;
  line-height: 1;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.vjs-waiting .vjs-loading-spinner, .vjs-seeking .vjs-loading-spinner {
  animation: 1.5s linear infinite spin;
  display: block;
}

.vjs-error .vjs-loading-spinner {
  animation: none;
  display: none;
}

.vjs-default-skin .vjs-loading-spinner:before {
  content: "";
  text-align: center;
  text-shadow: 0 0 .1em #000;
  width: 1em;
  height: 1em;
  font-family: VideoJS;
  position: absolute;
  top: 0;
  left: 0;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0);
  }

  100% {
    -o-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.vjs-default-skin .vjs-menu-button {
  float: right;
  cursor: pointer;
}

.vjs-default-skin .vjs-menu {
  border-top: 1.55em solid #07283280;
  border-left: 2em solid #0000;
  border-right: 2em solid #0000;
  width: 0;
  height: 0;
  margin-bottom: 3em;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.vjs-default-skin .vjs-menu-button .vjs-menu .vjs-menu-content {
  background-color: #07141eb3;
  width: 10em;
  max-height: 15em;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  bottom: 1.5em;
  left: -5em;
  overflow: auto;
  box-shadow: -.2em -.2em .3em #fff3;
}

.vjs-default-skin .vjs-menu-button:hover .vjs-control-content .vjs-menu, .vjs-default-skin .vjs-control-content .vjs-menu.vjs-lock-showing {
  display: block;
}

.vjs-default-skin.vjs-scrubbing .vjs-menu-button:hover .vjs-control-content .vjs-menu {
  display: none;
}

.vjs-default-skin .vjs-menu-button ul li {
  text-align: center;
  text-transform: lowercase;
  margin: 0;
  padding: .3em 0;
  font-size: 1.2em;
  line-height: 1.4em;
  list-style: none;
}

.vjs-default-skin .vjs-menu-button ul li.vjs-selected {
  background-color: #000;
}

.vjs-default-skin .vjs-menu-button ul li:focus, .vjs-default-skin .vjs-menu-button ul li:hover, .vjs-default-skin .vjs-menu-button ul li.vjs-selected:focus, .vjs-default-skin .vjs-menu-button ul li.vjs-selected:hover {
  color: #111;
  background-color: #ffffffbf;
  outline: 0;
  box-shadow: 0 0 1em #fff;
}

.vjs-default-skin .vjs-menu-button ul li.vjs-menu-title {
  text-align: center;
  text-transform: uppercase;
  cursor: default;
  margin: 0 0 .3em;
  padding: 0;
  font-size: 1em;
  font-weight: 700;
  line-height: 2em;
}

.vjs-default-skin .vjs-subtitles-button:before {
  content: "";
}

.vjs-default-skin .vjs-captions-button:before {
  content: "";
}

.vjs-default-skin .vjs-chapters-button:before {
  content: "";
}

.vjs-default-skin .vjs-chapters-button.vjs-menu-button .vjs-menu .vjs-menu-content {
  width: 24em;
  left: -12em;
}

.vjs-default-skin .vjs-captions-button:focus .vjs-control-content:before, .vjs-default-skin .vjs-captions-button:hover .vjs-control-content:before {
  box-shadow: 0 0 1em #fff;
}

.video-js {
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #000;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.video-js .vjs-tech {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js:-moz-full-screen {
  position: absolute;
}

body.vjs-full-window {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.video-js.vjs-fullscreen {
  z-index: 1000;
  _position: absolute;
  position: fixed;
  inset: 0;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}

.video-js.vjs-fullscreen.vjs-user-inactive {
  cursor: none;
}

.vjs-poster {
  cursor: pointer;
  background-color: #000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
}

.vjs-poster img {
  width: 100%;
  max-height: 100%;
  margin: 0 auto;
  padding: 0;
  display: block;
}

.video-js.vjs-has-started .vjs-poster {
  display: none;
}

.video-js.vjs-audio.vjs-has-started .vjs-poster {
  display: block;
}

.video-js.vjs-controls-disabled .vjs-poster, .video-js.vjs-using-native-controls .vjs-poster {
  display: none;
}

.video-js .vjs-text-track-display {
  pointer-events: none;
  position: absolute;
  inset: 0 0 3em;
}

.vjs-caption-settings {
  opacity: .75;
  color: #fff;
  background-color: #000;
  width: 40em;
  height: 15em;
  margin: 0 auto;
  padding: .5em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  position: relative;
  top: 1em;
}

.vjs-caption-settings .vjs-tracksettings {
  position: absolute;
  inset: 0 0 2em;
  overflow: auto;
}

.vjs-caption-settings .vjs-tracksettings-colors, .vjs-caption-settings .vjs-tracksettings-font {
  float: left;
}

.vjs-caption-settings .vjs-tracksettings-colors:after, .vjs-caption-settings .vjs-tracksettings-font:after, .vjs-caption-settings .vjs-tracksettings-controls:after {
  clear: both;
}

.vjs-caption-settings .vjs-tracksettings-controls {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.vjs-caption-settings .vjs-tracksetting {
  min-height: 40px;
  margin: 5px;
  padding: 3px;
}

.vjs-caption-settings .vjs-tracksetting label {
  width: 100px;
  margin-bottom: 5px;
  display: block;
}

.vjs-caption-settings .vjs-tracksetting span {
  margin-left: 5px;
  display: inline;
}

.vjs-caption-settings .vjs-tracksetting > div {
  min-height: 20px;
  margin-bottom: 5px;
}

.vjs-caption-settings .vjs-tracksetting > div:last-child {
  min-height: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.vjs-caption-settings label > input {
  margin-right: 10px;
}

.vjs-caption-settings input[type="button"] {
  width: 40px;
  height: 40px;
}

.vjs-hidden {
  display: none !important;
}

.vjs-lock-showing {
  opacity: 1;
  visibility: visible;
  display: block !important;
}

.vjs-no-js {
  color: #ccc;
  text-align: center;
  background-color: #333;
  width: 30em;
  height: 15em;
  margin: 0 auto;
  padding: 2em;
  font-family: Arial, sans-serif;
  font-size: 1.8em;
}

.vjs-no-js a, .vjs-no-js a:visited {
  color: #f4a460;
}

/*# sourceMappingURL=index.3a55bdcf.css.map */
